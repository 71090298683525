import React from "react";
import { FaLinkedin, FaInstagram, FaMapMarkerAlt } from "react-icons/fa";
import { BsPhone } from "react-icons/bs";
import { MdOutlineEmail } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";

import footerContent from "../../assets/contents/footer.json";

const BaseFooterComponent = () => {
  return (
    <footer className="bg-footer py-10 overflow-hidden">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-start px-4 md:px-8 flex-wrap">
        {/* Logo Section */}
        <div className="flex flex-col items-center md:items-start mb-8 md:mb-0">
          <img src={footerContent.company_logo} className="w-10 h-10 md:w-24 md:h-24" alt="company-logo" />
          <h3 className="text-xl font-semibold text-center md:text-left md:text-base mt-2">
            {footerContent.company_name}
          </h3>
          <p className="text-sm text-center md:text-left md:text-sm">
            {footerContent.company_group}
          </p>
          <div className="flex mt-2 space-x-4 text-2xl">
            <span className="text-gray-700 hover:text-gray-900">
              <a href={footerContent.linkedin_url} target="_blank" rel="noreferrer noopener">
                <FaLinkedin /> 
              </a>
            </span>
            <span className="text-gray-700 hover:text-gray-900">
              <a href={footerContent.instagram_url} target="_blank" rel="noreferrer noopener">
                <FaInstagram />
              </a>
            </span>
          </div>
        </div>

        {/* Coverage Section */}
        <div className="mt-8 mb-6 md:mb-0 text-center md:text-left">
          <h4 className="text-xl md:text-lg font-semibold mb-4">{footerContent.coverage.title}</h4>
          <div className="flex flex-col md:flex-row justify-center md:justify-start md:text-sm">
            <ul className="text-gray-700 space-y-2 md:mr-8">
              <li className="flex">
                <span className="text-lg font-bold"><IoIosArrowForward /></span>
                <p>{footerContent.coverage.sumatra}</p>
              </li>
              <li className="flex">
                <span className="text-lg font-bold"><IoIosArrowForward /></span>
                <p>{footerContent.coverage.banten}</p>
              </li>
              <li className="flex">
                <span className="text-lg font-bold"><IoIosArrowForward /></span>
                <p>{footerContent.coverage.jabodetabek}</p>
              </li>
              <li className="flex">
                <span className="text-lg font-bold"><IoIosArrowForward /></span>
                <p>{footerContent.coverage.jabar}</p>
              </li>
              <li className="flex">
                <span className="text-lg font-bold"><IoIosArrowForward /></span>
                <p>{footerContent.coverage.jateng}</p>
              </li>
            </ul>
            <ul className="text-gray-700 space-y-2 md:ml-2">
              <li className="flex">
                <span className="text-lg font-bold"><IoIosArrowForward /></span>
                <p>{footerContent.coverage.jatim}</p>
              </li>
              <li className="flex">
                <span className="text-lg font-bold"><IoIosArrowForward /></span>
                <p>{footerContent.coverage.balnus}</p>
              </li>
              <li className="flex">
                <span className="text-lg font-bold"><IoIosArrowForward /></span>
                <p>{footerContent.coverage.sulawesi}</p>
              </li>
            </ul>
          </div>
        </div>

        {/* Informasi Section */}
        <div className="text-left w-72 md:-mr-10 md:mt-6">
          <h4 className="text-xl font-semibold mb-4 md:text-lg">{footerContent.information.information_title}</h4>
          <address className="text-gray-700 not-italic space-y-2 md:text-sm">
            <div className="flex">
              <span className="text-secondary mr-2 mt-1 text-base"><FaMapMarkerAlt /></span>
              <p>{footerContent.information.company_address}</p>
            </div>
            <div className="flex">
                <span className="text-secondary mr-2 mt-1 text-base"><MdOutlineEmail /></span>
                <p>{footerContent.information.company_email}</p>
            </div>
            <div className="flex">
              <span className="text-secondary mr-2 mt-1 text-base">
                <BsPhone />
              </span>
              <p>{footerContent.information.company_phone}</p>
            </div>
          </address>
        </div>
      </div>
    </footer>
  );
};

export default BaseFooterComponent;