import React, { useState } from "react";
import { Link } from "react-router-dom";

const BaseNavigationComponent = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <nav className="fixed top-0 w-full bg-white p-4 border-b border-slate-200 shadow-md z-100">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/">
          <img 
            src="https://csi.ciapps.id/assets/img/bimasakti.png"
            alt="company-logo" 
            className="w-40 h-10 sm:w-52 sm:h-12" 
          />
        </Link>
        <div className="hidden lg:flex space-x-6 cursor-pointer">
          <Link to="/" className="text-black font-semibold hover:text-blue-500">Home</Link>
          <Link to="/tentangkami" className="text-black font-semibold hover:text-blue-500">Tentang Kami</Link>
          <Link to="/product" className="text-black font-semibold hover:text-blue-500">Product</Link>
          <Link to="/portfolio" className="text-black font-semibold hover:text-blue-500">Portofolio</Link>
          <Link to="/news" className="text-black font-semibold hover:text-blue-500">News</Link>
          <Link to="/clients&partners" className="text-black font-semibold hover:text-blue-500">Clients & Partners</Link>
        </div>
        <button 
          className="lg:hidden text-black focus:outline-none" 
          onClick={toggleMobileMenu}
        >
          {isMobileMenuOpen ? (
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          )}
        </button>
      </div>
      {/* tablet & mobile view */}
      {isMobileMenuOpen && (
        <div className="lg:hidden mt-6 px-4 border-t">
          <div className="flex flex-col space-y-4 cursor-pointer text-base mt-4">
            <Link to="/" className="text-black font-semibold hover:text-blue-500" onClick={closeMobileMenu}>Home</Link>
            <Link to="/tentangkami" className="text-black font-semibold hover:text-blue-500" onClick={closeMobileMenu}>Tentang Kami</Link>
            <Link to="/product" className="text-black font-semibold hover:text-blue-500" onClick={closeMobileMenu}>Product</Link>
            <Link to="/portfolio" className="text-black font-semibold hover:text-blue-500" onClick={closeMobileMenu}>Portofolio</Link>
            <Link to="/news" className="text-black font-semibold hover:text-blue-500" onClick={closeMobileMenu}>News</Link>
            <Link to="/clients&partners" className="text-black font-semibold hover:text-blue-500" onClick={closeMobileMenu}>Clients & Partners</Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default BaseNavigationComponent;