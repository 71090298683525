import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import BaseFooterComponent from '../components/footer/footer';
import BaseNavigationComponent from '../components/navbar/navbar';
import ScrollToTop from '../utils/ScrollToTop';

const HomePage = lazy(() => import('../pages/Home/home'))
const AboutUs = lazy(() => import('../pages/About/about-us'));
const Product = lazy(() => import('../pages/New-Product/new-product'));
const Portfolio = lazy(() => import('../pages/New-Portfolio/new-portfolio'));
const News = lazy(() => import('../pages/News/news'));
const ClientsPartners = lazy(() => import('../pages/Partners/partners'));
const Telecommunication = lazy(() => import('../pages/Services/Telecom/telecom'));
const Energy = lazy(() => import('../pages/Services/Energy/energy'));
const Security = lazy(() => import('../pages/Services/Security/security'));

function App() {
  return (
    <div className="">
      <Router>
        <ScrollToTop />
        <BaseNavigationComponent />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/tentangkami" element={<AboutUs />} />
            <Route path="/product" element={<Product />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/news" element={<News />} />
            <Route path="/clients&partners" element={<ClientsPartners />} />
            <Route path="/telecom" element={<Telecommunication />} />
            <Route path="/energy" element={<Energy />} />
            <Route path="/security" element={<Security />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Suspense>
        <BaseFooterComponent />
      </Router>
    </div>
  );
}

export default App;